import { ACTION_OPEN_MODAL } from '../actions/types';

const initialState = {
  opened: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_OPEN_MODAL:
      return { opened: action.payload.modal };

    default:
      return state;
  }
};
