import { UNITS_METRIC, UNITS_IMPERIAL } from 'config';
import { ACTION_CHANGE_UNITS } from '../actions/types';

const initialState =
  navigator.language.toLowerCase() !== 'en-us' ? UNITS_METRIC : UNITS_IMPERIAL;

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CHANGE_UNITS:
      return action.payload.value;

    default:
      return state;
  }
};
