import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import autocomplete from './autocomplete';
import units from './units';
import mydistance from './mydistance';
import compare from './compare';
import couldmet from './couldmet';
import highlights from './highlights';
import detail from './detail';
import birthdate from './birthdate';
import random from './random';
import favorites from './favorites';
import modals from './modals';

export default history =>
  combineReducers({
    auth,
    router: connectRouter(history),
    autocomplete,
    units,
    mydistance,
    compare,
    couldmet,
    highlights,
    detail,
    birthdate,
    random,
    favorites,
    modals,
  });
