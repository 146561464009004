import { createComponentWithProxy } from 'react-fela';

const Component = createComponentWithProxy(({ column }) => ({
  display: 'flex',
  flexDirection: column ? 'column' : 'row',
  outline: 'none',
  ':focus': {
    outline: 'none',
  },
}));

export default Component;
