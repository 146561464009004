import { createComponent } from 'react-fela';

const Avatar = createComponent(({ url, noBorder, theme, size = 100 }) => ({
  borderRadius: '100%',
  backgroundImage: `url(${url ||
    'https://i.pinimg.com/originals/fd/0c/55/fd0c559856ca991e9e28937dc802f0b0.png'})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: `${size}px`,
  height: `${size}px`,
  border: noBorder ? 0 : `1px solid ${theme.borderColor}`,
}));

export default Avatar;
