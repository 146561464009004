import React from 'react';
import PropTypes from 'prop-types';

import { createComponent } from 'react-fela';

import Flex from 'components/Flex';
import Footer from 'components/Footer';

const Wrapper = createComponent(
  {
    margin: '40px',
    flex: '1 1 calc(100% - 580px)',
    mobile: {
      margin: '20px',
    },
    color: '#fff',
  },
  Flex,
);

const Content = ({ children }) => (
  <Wrapper column>
    {children}
    <Footer />
  </Wrapper>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
