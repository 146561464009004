import { ACTION_AUTOCOMPLETE_PERSON } from '../actions/types';
import { suffixStart, suffixDone, suffixError } from '../actions/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case suffixStart(ACTION_AUTOCOMPLETE_PERSON):
      return {
        ...state,
        [action.payload.query]: {
          loading: true,
          data: [],
        },
      };

    case suffixDone(ACTION_AUTOCOMPLETE_PERSON):
      return {
        ...state,
        [action.payload.query]: {
          loading: false,
          data: action.payload.data,
        },
      };

    case suffixError(ACTION_AUTOCOMPLETE_PERSON):
      return {
        ...state,
        [action.payload.query]: {
          loading: false,
          data: [],
          error: action.payload.message,
        },
      };

    default:
      return state;
  }
};
