import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_COULD_MET } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchCouldMetRequest = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_COULD_MET)),
    switchMap(action => {
      const { personA, personB } = action.payload;

      const fetchPerson = id =>
        firebase
          .firestore()
          .collection('people')
          .doc(id)
          .get()
          .then(doc => doc.exists && doc.data());

      const couldTheyMet = firebase.functions().httpsCallable('couldTheyMet');

      const promise = couldTheyMet({ idx: personA.id, idy: personB.id }).then(
        ({ data }) =>
          Promise.all([fetchPerson(personA.id), fetchPerson(personB.id)]).then(
            people => ({
              couldTheyMet: data,
              people,
            }),
          ),
      );

      return from(promise).pipe(
        map(data => done(ACTION_COULD_MET, data)),
        catchError(e =>
          of(
            error(ACTION_COULD_MET, {
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchCouldMetRequest];
