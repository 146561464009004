import { from, of } from 'rxjs';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_AUTOCOMPLETE_PERSON } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchPersonAutocomplete = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_AUTOCOMPLETE_PERSON)),
    debounceTime(500),
    switchMap(action => {
      const { query } = action.payload;

      const autocompletePerson = firebase
        .functions()
        .httpsCallable('autocompletePerson');

      return from(autocompletePerson({ query })).pipe(
        map(({ data }) => done(ACTION_AUTOCOMPLETE_PERSON, { query, data })),
        catchError(e =>
          of(error(ACTION_AUTOCOMPLETE_PERSON, { query, message: e.message })),
        ),
      );
    }),
  );

export default [watchPersonAutocomplete];
