import React from 'react';
import { StoreContext } from 'redux-react-hook';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import createStore from 'common/createStore';

const store = createStore();

const StoreProvider = ({ children }) => (
  <Provider store={store}>
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  </Provider>
);

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StoreProvider;
