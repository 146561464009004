import { assoc, reduce } from 'ramda';
import {
  ACTION_ADD_FAVORITE,
  ACTION_REMOVE_FAVORITE,
  ACTION_FAVORITE_FETCH,
} from '../actions/types';
import { suffixDone } from '../actions/utils';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ADD_FAVORITE:
      return {
        ...state,
        [action.payload.uid]: true,
      };

    case ACTION_REMOVE_FAVORITE:
      return {
        ...state,
        [action.payload.uid]: false,
      };

    case suffixDone(ACTION_FAVORITE_FETCH):
      return reduce((acc, el) => assoc(el, true, acc), {}, action.payload);

    default:
      return state;
  }
};
