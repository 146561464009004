import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, createComponentWithProxy } from 'react-fela';
import ButtonBase from 'components/Button';

const camalize = str =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const Button = createComponentWithProxy(
  ({ disabled }) => ({
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '1.3s',
    ':hover': !disabled && {
      backgroundColor: '#2a3142',

      '> div': {
        width: '100%',
        height: '100%',
      },
    },
  }),
  ButtonBase,
);

const Corner = createComponent(({ theme }) => ({
  position: 'absolute',
  width: '10px',
  height: '7px',
  transition: '.5s',
  borderColor: theme.borderColor,
  color: theme.borderColor,
}));

const cornerStyle = (pos, attrs) =>
  pos.split(',').reduce(
    (style, prop) => ({
      ...style,
      [prop]: 0,
      [camalize(`border ${prop}`)]: '1px solid',
    }),
    {
      ...attrs,
    },
  );

const CornerButton = ({ children, ...props }) => (
  <Button {...props}>
    <Corner extend={cornerStyle('top,left', { width: '100%' })} />
    <Corner extend={cornerStyle('top,right', { maxWidth: '10px' })} />
    <Corner extend={cornerStyle('bottom,left', { maxWidth: '10px' })} />
    <Corner extend={cornerStyle('bottom,right', { width: '100%' })} />
    <span>{children}</span>
  </Button>
);

CornerButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CornerButton;
