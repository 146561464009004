import { from, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  ACTION_AUTH,
  ACTION_ADD_FAVORITE,
  ACTION_REMOVE_FAVORITE,
  ACTION_FAVORITE_FETCH,
} from '../actions/types';
import { done } from '../actions/utils';

const getFavoritesCollection = firebase =>
  firebase
    .firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid)
    .collection('favorites');

const fetchFavoritesEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(ACTION_AUTH),
    switchMap(action => {
      const { uid } = action.payload || {};

      if (!uid) {
        return of();
      }

      return from(getFavoritesCollection(firebase).get()).pipe(
        map(snap => done(ACTION_FAVORITE_FETCH, snap.docs.map(d => d.id))),
      );
    }),
  );

const addFavoriteEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(ACTION_ADD_FAVORITE),
    switchMap(action => {
      const { uid } = action.payload;

      return from(
        getFavoritesCollection(firebase)
          .doc(uid)
          .set({
            created: new Date(),
          })
          .then(() => done(ACTION_ADD_FAVORITE, action.payload)),
      );
    }),
  );

const removeFavoriteEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(ACTION_REMOVE_FAVORITE),
    switchMap(action => {
      const { uid } = action.payload;

      return from(
        getFavoritesCollection(firebase)
          .doc(uid)
          .delete()
          .then(() => done(ACTION_REMOVE_FAVORITE, action.payload)),
      );
    }),
  );

export default [addFavoriteEpic, removeFavoriteEpic, fetchFavoritesEpic];
