export const suffixStart = action => [action, 'START'].join('_');
export const suffixDone = action => [action, 'DONE'].join('_');
export const suffixError = action => [action, 'ERROR'].join('_');

export const start = (action, payload = {}) => ({
  type: suffixStart(action),
  payload,
});
export const done = (action, payload = {}) => ({
  type: suffixDone(action),
  payload,
});
export const error = (action, payload = {}) => ({
  type: suffixError(action),
  payload,
});
