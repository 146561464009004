import React from 'react';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { UNITS_METRIC } from 'config';
import { useMappedState } from 'redux-react-hook';
import { FormattedMessage } from 'react-intl';
import Flex from 'components/Flex';
import BoxHeading from 'components/BoxHeading';
import CornerContent from 'components/CornerContent';
import ParagraphBase from 'components/Paragraph';

const earthImageUrl = require('../infografika_1-km.png');
const earthImageMileUrl = require('../infografika_1-mi.png');
const elevationImageUrl = require('../infografika_2-km.png');
const elevationImageMileUrl = require('../infografika_2-mi.png');

const Column = createComponent(
  {
    margin: '40px',
    flex: '0 0 250px',
    flexDirection: 'column',
    mobile: {
      display: 'none',
    },
  },
  Flex,
);

const Paragraph = createComponent(
  {
    fontSize: '13px',
    padding: '12px',
  },
  ParagraphBase,
);

const Image = createComponentWithProxy(
  {
    maxWidth: '100%',
    width: '100%',
  },
  'img',
);

const RightPanel = () => {
  const usedUnits = useMappedState(({ units }) => units);

  return (
    <Column>
      <BoxHeading>
        <FormattedMessage
          id="rightpanel.title.distance"
          defaultMessage="Max. distance"
        >
          {text => <BoxHeading>{text}</BoxHeading>}
        </FormattedMessage>
      </BoxHeading>
      <CornerContent>
        <Image
          src={usedUnits === UNITS_METRIC ? earthImageUrl : earthImageMileUrl}
          alt="Earth"
        />
        <FormattedMessage
          id="rightpanel.text.distance"
          defaultMessage="Distance text"
        >
          {text => <Paragraph>{text}</Paragraph>}
        </FormattedMessage>
      </CornerContent>

      <FormattedMessage
        id="rightpanel.title.elevation"
        defaultMessage="Max. elevation"
      >
        {text => <BoxHeading extend={{ marginTop: '40px' }}>{text}</BoxHeading>}
      </FormattedMessage>

      <CornerContent>
        <Image
          src={
            usedUnits === UNITS_METRIC
              ? elevationImageUrl
              : elevationImageMileUrl
          }
          alt="Elevation"
        />
        <FormattedMessage
          id="rightpanel.text.elevation"
          defaultMessage="Elevation text"
        >
          {text => <Paragraph>{text}</Paragraph>}
        </FormattedMessage>
      </CornerContent>
    </Column>
  );
};

export default RightPanel;
