import React from 'react';

import { createComponent } from 'react-fela';

import Flex from 'components/Flex';
import SidebarLinks from 'components/SidebarLinks';

const Wrapper = createComponent(
  {
    backgroundColor: '#2a3142',
    minHeight: '100vh',
    flex: '0 0 250px',
    mobile: {
      display: 'none',
    },
  },
  Flex,
);

const Inner = createComponent(
  {
    padding: '20px 0',
    width: '100%',
  },
  Flex,
);

const Sidebar = () => (
  <Wrapper>
    <Inner column>
      <SidebarLinks />
    </Inner>
  </Wrapper>
);

export default Sidebar;
