import { combineReducers } from 'redux';
import { assoc } from 'ramda';
import { ACTION_FETCH_PERSON } from '../actions/types';
import { suffixDone } from '../actions/utils';
import { createLoadingReducer } from './utils';

const loading = createLoadingReducer(ACTION_FETCH_PERSON);

const initialState = {};

const people = (state = initialState, action) => {
  switch (action.type) {
    case suffixDone(ACTION_FETCH_PERSON):
      return assoc(action.payload.id, action.payload, state);

    default:
      return state;
  }
};

export default combineReducers({ loading, people });
