import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  ACTION_SIGNIN,
  ACTION_SIGN_OUT,
  ACTION_SIGNUP,
} from 'common/actions/types';
import { suffixStart, suffixDone, done, error } from 'common/actions/utils';
import { push } from 'connected-react-router';

const watchSigninAttempt = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_SIGNIN)),
    switchMap(action => {
      const { email, password } = action.payload;

      return from(
        firebase.auth().signInWithEmailAndPassword(email, password),
      ).pipe(
        map(response => done(ACTION_SIGNIN, response)),
        catchError(e => of(error(ACTION_SIGNIN, { message: e.message }))),
      );
    }),
  );

const watchSignupAttempt = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_SIGNUP)),
    switchMap(action => {
      const { email, password, nickname } = action.payload;

      const signUpPromise = firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async authData => {
          await firebase
            .firestore()
            .collection('users')
            .doc(authData.user.uid)
            .set({
              email,
              displayName: nickname,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

          await firebase.auth().currentUser.updateProfile({
            displayName: nickname,
          });

          return {
            ...authData.user,
            email,
            displayName: nickname,
          };
        });

      return from(signUpPromise).pipe(
        map(response => done(ACTION_SIGNUP, response)),
        catchError(e => of(error(ACTION_SIGNUP, { message: e.message }))),
      );
    }),
  );

const watchSignoutAttempt = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_SIGN_OUT)),
    switchMap(() => {
      const signOutPromise = firebase.auth().signOut();

      return from(signOutPromise).pipe(
        map(response => done(ACTION_SIGN_OUT, response)),
        catchError(e => of(error(ACTION_SIGN_OUT, { message: e.message }))),
      );
    }),
  );

const watchSigninDone = action$ =>
  action$.pipe(
    ofType(suffixDone(ACTION_SIGNIN)),
    // after sign-in redirect
    map(() => push('/welcome')),
  );

export default [
  watchSigninDone,
  watchSigninAttempt,
  watchSignupAttempt,
  watchSignoutAttempt,
];
