import React from 'react';
import { createComponent, createComponentWithProxy } from 'react-fela';

const Container = createComponent({
  width: '32px',
  height: '32px',
});

const Svg = createComponentWithProxy(
  {
    height: '100%',
    width: '100%',
    fill: '#fff',
  },
  'svg',
);

const Loader = props => (
  <Container {...props}>
    <Svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 50 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <g>
        <circle stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </g>
    </Svg>
  </Container>
);

export default Loader;
