import { start } from './utils';
import {
  ACTION_SIGNIN,
  ACTION_SIGNUP,
  ACTION_AUTOCOMPLETE_PERSON,
  ACTION_CHANGE_UNITS,
  ACTION_MEASURE_MY_DISTANCE,
  ACTION_COMPARE_ADD,
  ACTION_COULD_MET,
  ACTION_FETCH_HIGHLIGHTS,
  ACTION_FETCH_PERSON,
  ACTION_SEARCH_DATE,
  ACTION_COMPARE_REMOVE,
  ACTION_RANDOM_PERSON,
  ACTION_SIGN_OUT,
  ACTION_FETCH_GROUP,
  ACTION_ADD_FAVORITE,
  ACTION_REMOVE_FAVORITE,
  ACTION_OPEN_MODAL,
} from './types';

export const signin = fields => start(ACTION_SIGNIN, fields);
export const signup = fields => start(ACTION_SIGNUP, fields);
export const signOut = () => start(ACTION_SIGN_OUT);

export const changeUnits = value => ({
  type: ACTION_CHANGE_UNITS,
  payload: { value },
});

export const autocompletePerson = query =>
  start(ACTION_AUTOCOMPLETE_PERSON, { query });

export const measureMyDistance = (name, distanceFrom, distanceTo) =>
  start(ACTION_MEASURE_MY_DISTANCE, {
    name,
    distanceFrom,
    distanceTo,
  });

export const addToCompare = person => start(ACTION_COMPARE_ADD, person);
export const randomPerson = () => start(ACTION_RANDOM_PERSON);
export const removeFromCompare = person => start(ACTION_COMPARE_REMOVE, person);
export const couldMet = (personA, personB) =>
  start(ACTION_COULD_MET, { personA, personB });
export const fetchHighlights = () => start(ACTION_FETCH_HIGHLIGHTS);
export const fetchPerson = id => start(ACTION_FETCH_PERSON, { id });
export const fetchGroup = id => start(ACTION_FETCH_GROUP, { id });
export const searchDate = (day, month) =>
  start(ACTION_SEARCH_DATE, { day, month });

export const addFavorite = uid => ({
  type: ACTION_ADD_FAVORITE,
  payload: { uid },
});

export const removeFavorite = uid => ({
  type: ACTION_REMOVE_FAVORITE,
  payload: { uid },
});

export const openModal = modal => ({
  type: ACTION_OPEN_MODAL,
  payload: { modal },
});
