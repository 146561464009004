import { suffixStart, suffixDone, suffixError } from '../actions/utils';

export const createLoadingReducer = actionType => (state = false, action) => {
  switch (action.type) {
    case suffixStart(actionType):
      return true;

    case suffixDone(actionType):
    case suffixError(actionType):
      return false;

    default:
      return state;
  }
};
