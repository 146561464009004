import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_FETCH_HIGHLIGHTS } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchCouldMetRequest = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_FETCH_HIGHLIGHTS)),
    switchMap(() => {
      const fetchHighlights = firebase
        .functions()
        .httpsCallable('fetchHighlights');

      const fetchPerson = id =>
        firebase
          .firestore()
          .collection('people')
          .doc(id)
          .get()
          .then(
            doc =>
              doc.exists && {
                id: doc.id,
                ...doc.data(),
              },
          );

      const promise = fetchHighlights().then(({ data }) =>
        Promise.all(data.map(row => fetchPerson(row.id))),
      );
      return from(promise).pipe(
        map(data => done(ACTION_FETCH_HIGHLIGHTS, data)),
        catchError(e =>
          of(
            error(ACTION_FETCH_HIGHLIGHTS, {
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchCouldMetRequest];
