import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_MEASURE_MY_DISTANCE } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchDistanceMeasure = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_MEASURE_MY_DISTANCE)),
    switchMap(action => {
      const { name, distanceFrom, distanceTo } = action.payload;

      const measureDistance = firebase
        .functions()
        .httpsCallable('measureDistance');

      const start = {
        latitude: distanceFrom.latlng.lat,
        longitude: distanceFrom.latlng.lng,
      };

      const end = {
        latitude: distanceTo.latlng.lat,
        longitude: distanceTo.latlng.lng,
      };

      return from(measureDistance({ start, end })).pipe(
        map(({ data }) =>
          done(ACTION_MEASURE_MY_DISTANCE, {
            name,
            distanceFrom,
            distanceTo,
            data,
          }),
        ),
        catchError(e =>
          of(
            error(ACTION_MEASURE_MY_DISTANCE, {
              name,
              distanceFrom,
              distanceTo,
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchDistanceMeasure];
