import { ACTION_MEASURE_MY_DISTANCE } from '../actions/types';
import { suffixStart, suffixDone, suffixError } from '../actions/utils';

const initialState = {
  loading: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case suffixStart(ACTION_MEASURE_MY_DISTANCE):
      return {
        ...action.payload,
        loading: true,
      };

    case suffixDone(ACTION_MEASURE_MY_DISTANCE):
    case suffixError(ACTION_MEASURE_MY_DISTANCE):
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
