import React from 'react';
import Flex from './Flex';
import Text from './Text';
import PaypalButton from './PaypalButton';

export default () => (
  <Flex column extend={{ marginTop: '50px', alignItems: 'center' }}>
    <Text color="#343d4f">
      &copy; {new Date().getFullYear()} EatMyDust.info
    </Text>
    <Flex column extend={{ marginTop: '10px', alignItems: 'center' }}>
      <PaypalButton />
    </Flex>
  </Flex>
);
