import { from, of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { ACTION_RANDOM_PERSON } from 'common/actions/types';
import { suffixStart, error, done } from 'common/actions/utils';

const watchRandomPerson = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_RANDOM_PERSON)),
    switchMap(() => {
      const randomPerson = firebase.functions().httpsCallable('randomPerson');

      const promise = randomPerson().then(({ data }) => data);

      return from(promise).pipe(
        mergeMap(data =>
          of(done(ACTION_RANDOM_PERSON, data), push(`/detail/${data.id}`)),
        ),
        catchError(e =>
          of(
            error(ACTION_RANDOM_PERSON, {
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchRandomPerson];
