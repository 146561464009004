import { empty } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

const routeChangedEpic = (action$, state$, { ReactGA }) =>
  action$.pipe(
    ofType('@@router/LOCATION_CHANGE'),
    switchMap(action => {
      const { payload } = action;

      if (payload && payload.location) {
        ReactGA.pageview(payload.location.pathname + payload.location.search);
      }

      return empty();
    }),
  );

export default [routeChangedEpic];
