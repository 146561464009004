import React from 'react';
import { IntlProvider as Provider } from 'react-intl';
import PropTypes from 'prop-types';
import enMessages from 'translations/locales/en.json';

const IntlProvider = ({ children }) => (
  <Provider locale="en" messages={enMessages}>
    {children}
  </Provider>
);

IntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default IntlProvider;
