import { createComponent } from 'react-fela';

const textStyle = ({
  fontWeight = 300,
  fontSize = '1rem',
  color = 'inherit',
}) => ({
  fontFamily: 'Roboto, sans-serif',
  color,
  fontSize,
  fontWeight,
});

export const Text = createComponent(textStyle, 'span');

export default Text;
