import { of, empty } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { openModal } from '../actions';

const wakeUpEpic = action$ =>
  action$.pipe(
    debounceTime(3 * 60 * 1000), // 3 minutes
    switchMap(action => {
      if (action.type === 'OPEN_MODAL') {
        return empty();
      }

      return of(openModal('wakeup'));
    }),
  );

export default [wakeUpEpic];
