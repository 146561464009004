export const ACTION_AUTH = 'AUTH_CHANGED';
export const ACTION_CHANGE_UNITS = 'CHANGE_UNITS';
export const ACTION_SIGNIN = 'SIGNIN';
export const ACTION_SIGNUP = 'SIGNUP';
export const ACTION_AUTOCOMPLETE_PERSON = 'AUTOCOMPLETE_PERSON';
export const ACTION_MEASURE_MY_DISTANCE = 'MEASURE_MY_DISTANCE';
export const ACTION_COMPARE_ADD = 'COMPARE_ADD';
export const ACTION_COMPARE_REMOVE = 'ACTION_COMPARE_REMOVE';
export const ACTION_COULD_MET = 'COULD_MET';
export const ACTION_FETCH_HIGHLIGHTS = 'FETCH_HIGHLIGHTS';
export const ACTION_FETCH_PERSON = 'FETCH_PERSON';
export const ACTION_FETCH_GROUP = 'FETCH_GROUP';
export const ACTION_SEARCH_DATE = 'SEARCH_DATE';
export const ACTION_RANDOM_PERSON = 'RANDOM_PERSON';
export const ACTION_SIGN_OUT = 'SIGN_OUT';
export const ACTION_ADD_FAVORITE = 'ADD_FAVORITE';
export const ACTION_REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const ACTION_FAVORITE_FETCH = 'FAVORITE_FETCH';
export const ACTION_OPEN_MODAL = 'OPEN_MODAL';
