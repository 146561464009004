import { ACTION_AUTH, ACTION_SIGNUP, ACTION_SIGN_OUT } from '../actions/types';
import { suffixDone } from '../actions/utils';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_AUTH:
      return {
        ...action.payload,
      };

    case suffixDone(ACTION_SIGNUP):
      return {
        ...action.payload,
      };

    case suffixDone(ACTION_SIGN_OUT):
      return initialState;

    default:
      return state;
  }
};
