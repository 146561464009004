import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, createComponentWithProxy } from 'react-fela';
import Flex from 'components/Flex';

const camalize = str =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const Content = createComponentWithProxy(
  () => ({
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  Flex,
);

const Corner = createComponent(() => ({
  position: 'absolute',
  width: '10px',
  height: '20px',
  borderColor: '#525f83',
  color: '#525f83',
}));

const cornerStyle = (pos, attrs) =>
  pos.split(',').reduce(
    (style, prop) => ({
      ...style,
      [prop]: 0,
      [camalize(`border ${prop}`)]: '1px solid',
    }),
    {
      ...attrs,
    },
  );

const contentStyle = { width: '100%' };

const CornerContent = ({ children, ...props }) => (
  <Content {...props}>
    <Corner
      extend={cornerStyle('top,left', { width: '100%', maxHeight: '20px' })}
    />
    <Corner extend={cornerStyle('top,right', { maxWidth: '10px' })} />
    <Corner extend={cornerStyle('bottom,left', { maxWidth: '10px' })} />
    <Corner
      extend={cornerStyle('bottom,right', { width: '100%', maxHeight: '20px' })}
    />
    <Flex column extend={contentStyle}>
      {children}
    </Flex>
  </Content>
);

CornerContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CornerContent;
