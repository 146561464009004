import { useEffect } from 'react';
import 'cookieconsent';

export default () => {
  useEffect(() => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: 'rgba(239, 239, 239, 0.7)',
          text: '#404040',
        },
        button: {
          background: '#8ec760',
          text: '#ffffff',
        },
      },
      theme: 'classic',
      position: 'bottom',
    });
  }, []);

  return null;
};
