import { combineEpics } from 'redux-observable';
import authEpics from './auth';
import autocompleteEpics from './autocomplete';
import mydistanceEpics from './mydistance';
import compareEpics from './compare';
import couldmetEpics from './couldmet';
import highlightsEpics from './highlights';
import detailEpics from './detail';
import birthdateEpics from './birthdate';
import randomEpics from './random';
import favoritesEpics from './favorites';
import gaEpics from './ga';
import modalsEpics from './modals';

export default () =>
  combineEpics(
    ...authEpics,
    ...autocompleteEpics,
    ...compareEpics,
    ...mydistanceEpics,
    ...couldmetEpics,
    ...highlightsEpics,
    ...detailEpics,
    ...birthdateEpics,
    ...randomEpics,
    ...favoritesEpics,
    ...gaEpics,
    ...modalsEpics,
  );
