import { combineReducers } from 'redux';
import { assoc, dissoc } from 'ramda';
import { ACTION_COMPARE_ADD, ACTION_COMPARE_REMOVE } from '../actions/types';
import { suffixStart, suffixDone } from '../actions/utils';
import { createLoadingReducer } from './utils';

const loading = createLoadingReducer(ACTION_COMPARE_ADD);

const people = (state = {}, action) => {
  switch (action.type) {
    case suffixDone(ACTION_COMPARE_ADD):
      return assoc(action.payload.id, action.payload.data, state);

    case suffixStart(ACTION_COMPARE_REMOVE):
      return dissoc(action.payload.id, state);
    default:
      return state;
  }
};

export default combineReducers({ loading, people });
