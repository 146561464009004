import { combineReducers } from 'redux';
import { ACTION_FETCH_HIGHLIGHTS } from '../actions/types';
import { suffixDone } from '../actions/utils';
import { createLoadingReducer } from './utils';

const loading = createLoadingReducer(ACTION_FETCH_HIGHLIGHTS);

const initialState = [];

const data = (state = initialState, action) => {
  switch (action.type) {
    case suffixDone(ACTION_FETCH_HIGHLIGHTS):
      return [...action.payload];

    default:
      return state;
  }
};

export default combineReducers({ loading, data });
