import React from 'react';

import { createComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useMappedState } from 'redux-react-hook';

import Link from 'components/Link';

import { paths } from 'routes';

const Item = createComponent(
  ({ href, active }) => ({
    color: '#fff',
    width: '100%',
    padding: '10px 25px',
    backgroundColor: (active === href && '#343d4f') || null,
    ':hover': {
      backgroundColor: '#343d4f',
    },
  }),
  Link,
  ['href', 'active'],
);

const SidebarLinks = () => {
  const pathname = useMappedState(
    ({ router: { location } }) => location && location.pathname,
  );

  return (
    <React.Fragment>
      <Item href={paths.WELCOME} active={pathname}>
        <FormattedMessage id="sidebar.link.welcome" defaultMessage="Welcome" />
      </Item>
      <Item href={paths.HIGHLIGHTS} active={pathname}>
        <FormattedMessage
          id="sidebar.link.highlights"
          defaultMessage="Pick of the day"
        />
      </Item>
      <Item href={paths.COMPARE} active={pathname}>
        <FormattedMessage id="sidebar.link.compare" defaultMessage="Compare" />
      </Item>
      <Item href={paths.COULDTHEYMET} active={pathname}>
        <FormattedMessage
          id="sidebar.link.couldtheymet"
          defaultMessage="Could they have met?"
        />
      </Item>
      <Item href={paths.MEASUREMYDISTANCE} active={pathname}>
        <FormattedMessage
          id="sidebar.link.measuredistance"
          defaultMessage="Measure my distance"
        />
      </Item>
      <Item href={paths.BORNONDATE} active={pathname}>
        <FormattedMessage
          id="sidebar.link.bornondate"
          defaultMessage="Born on my birthday"
        />
      </Item>
      {/* <Item href={paths.RECENTLISTS} active={pathname}>
        <FormattedMessage
          id="sidebar.link.recentgroups"
          defaultMessage="Recent public groups"
        />
      </Item> */}
      <Item href={paths.LISTS} active={pathname}>
        <FormattedMessage
          id="sidebar.link.groups"
          defaultMessage="Public groups"
        />
      </Item>
      <Item href={paths.STATISTICS} active={pathname}>
        <FormattedMessage
          id="sidebar.link.statistics"
          defaultMessage="Statistics"
        />
      </Item>
      <Item href={paths.ABOUT} active={pathname}>
        <FormattedMessage
          id="sidebar.link.about"
          defaultMessage="About project"
        />
      </Item>
      <Item href={paths.DOWNLOAD} active={pathname}>
        <FormattedMessage
          id="sidebar.link.download"
          defaultMessage="Download"
        />
      </Item>
    </React.Fragment>
  );
};

export default SidebarLinks;
