import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_FETCH_PERSON } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchCouldMetRequest = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_FETCH_PERSON)),
    switchMap(action => {
      const { id } = action.payload;

      const promise = firebase
        .firestore()
        .collection('people')
        .doc(id)
        .get()
        .then(doc => ({
          id,
          ...doc.data(),
        }))
        .then(async data => {
          if (!data.birthPlace || !data.deathPlace) {
            return data;
          }

          const elKey = [data.birthPlace.id, data.deathPlace.id]
            .sort()
            .reverse()
            .join('_');

          let elevation = await firebase
            .firestore()
            .collection('elevations')
            .doc(elKey)
            .get()
            .then(doc => doc.data());

          if (!elevation) {
            return data;
          }

          if (elKey.indexOf(data.birthPlace.id) > 0) {
            elevation = {
              points: elevation.points.reverse(),
              distance: elevation.distance,
            };
          }

          return {
            ...data,
            elevation,
          };
        });

      return from(promise).pipe(
        map(data => done(ACTION_FETCH_PERSON, data)),
        catchError(e =>
          of(
            error(ACTION_FETCH_PERSON, {
              id,
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchCouldMetRequest];
