const theme = {
  borderColor: '#18ff80',
  buttons: {
    primary: {
      backgroundColor: '#252a3a',
      color: '#fff',
    },

    primaryDisabled: {
      backgroundColor: '#252a3a',
      opacity: '0.7',
      cursor: 'not-allowed',
    },
  },
  colors: {
    red: '#e84949',
    green: '#18ff80',
    yellow: 'yellow',
  },

  backgrounds: {
    odd: '#202633',
    even: '#2a3142',
  },

  charts: {
    green: '#18ff80',
    gridColor: '#293042',
    tickColor: '#fff',
    tickSize: 13,
  },
};

export default theme;
