import { combineReducers } from 'redux';
import { ACTION_COULD_MET } from '../actions/types';
import { suffixDone } from '../actions/utils';
import { createLoadingReducer } from './utils';

const loading = createLoadingReducer(ACTION_COULD_MET);

const initialState = {
  people: [],
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case suffixDone(ACTION_COULD_MET):
      return { ...action.payload };

    default:
      return state;
  }
};

export default combineReducers({ loading, data });
