import React from 'react';
import { BreakpointProvider } from 'react-socks';
import Routes from './routes';
import FelaProvider from './containers/FelaProvider';
import StoreProvider from './containers/StoreProvider';
import IntlProvider from './containers/IntlProvider';
import CookieConsent from './components/CookieConsent';

const App = () => (
  <StoreProvider>
    <IntlProvider>
      <FelaProvider>
        <BreakpointProvider>
          <React.Fragment>
            <CookieConsent />
            <Routes />
          </React.Fragment>
        </BreakpointProvider>
      </FelaProvider>
    </IntlProvider>
  </StoreProvider>
);

export default App;
