import React, { useState } from 'react';
import { Breakpoint } from 'react-socks';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { stack as Menu } from 'react-burger-menu';
import { push } from 'connected-react-router';
import { signOut, randomPerson } from 'common/actions';
import CornerButton from 'components/CornerButton';
import Button from 'components/Button';
import ClickAwayListener from 'components/ClickAwayListener';
import Flex from 'components/Flex';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Avatar from 'components/Avatar';
import SidebarLinks from 'components/SidebarLinks';
import UnitSwitch from 'components/UnitSwitch';
import PersonAutocomplete from 'components/PersonAutocomplete';

import { paths } from 'routes';

const cornerButtonStyle = { margin: '0 10px' };
const randomButtonStyle = { margin: '10px 15px 20px' };
const menuButtonoStyle = {
  padding: '0px',
  margin: '0px',
  background: 'transparent',
};

const Wrapper = createComponent(
  ({ transparent }) => ({
    padding: '15px',
    flex: '0 1 300px',
    width: '300px',
    background: transparent ? null : 'rgb(42, 49, 66, 0.9)',
    alignItems: 'center',
  }),
  Flex,
);

const MobileWrapper = createComponent(
  () => ({
    alignItems: 'center',
    justifyItems: 'center',
    padding: '0 15px',
  }),
  Flex,
);

const MobileAuthWrapper = createComponent(
  () => ({
    alignItems: 'center',
    justifyItems: 'center',
    padding: '15px 20px',
    marginTop: '10px',
    borderTop: '1px solid #343d4f',
  }),
  Flex,
);

const Name = createComponent(
  ({ theme }) => ({ color: theme.colors.green, marginBottom: '10px' }),
  'span',
);

const AccLink = createComponent({ color: '#fff', fontSize: '0.8rem' }, Link, [
  'onClick',
  'href',
]);

const AccAvatar = createComponent(
  {
    width: '50px',
    height: '50px',
    marginRight: '15px',
    borderColor: '#525d7f',
  },
  Avatar,
);

const styles = {
  bmBurgerButton: {
    width: '36px',
    height: '25px',
    position: 'relative',
  },
  bmBurgerBars: {
    background: '#fff',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
  },
  bmMenu: {
    background: '#2a3142',
    padding: '0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'flex',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

const accLinkMargin = { marginLeft: '15px' };

const SvgLine = createComponentWithProxy(
  {
    fill: 'none',
    stroke: '#fff',
    strokeWidth: 6,
    strokeMiterlimit: 10,
  },
  'line',
);
const HamurgerIcon = () => (
  <svg
    id="Слой_1"
    style={{ height: '40px' }}
    version="1.1"
    viewBox="0 0 139 139"
  >
    <SvgLine id="XMLID_6_" x1="26.5" x2="112.5" y1="46.3" y2="46.3" />
    <SvgLine id="XMLID_9_" x1="26.5" x2="112.5" y1="92.7" y2="92.7" />
    <SvgLine id="XMLID_8_" x1="26.5" x2="112.5" y1="69.5" y2="69.5" />
  </svg>
);

const autocompleteStyle = {
  width: '100%',
  margin: '10px 0',
  padding: '0 15px',
};

const AuthNav = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [pathname, setPathname] = useState(null);
  const onSignoutClick = () => {
    setIsOpen(false);
    dispatch(signOut());
  };

  const user = useMappedState(({ auth }) => auth);
  const isLoggedIn = user && !!user.uid;

  const onPersonSelect = person => {
    if (person) {
      setIsOpen(false);
      dispatch(push(`/detail/${person.id}`));
    }
  };

  const { loading: isRandomLoading, location } = useMappedState(state => ({
    loading: state.random.loading,
    location: state.router.location,
  }));

  if (location.pathname !== pathname) {
    if (pathname !== null) {
      setIsOpen(false);
    }

    setPathname(location.pathname);
  }

  const onRandomClick = () => {
    // setIsOpen(false);
    dispatch(randomPerson());
  };

  const isMenuOpen = state => {
    setIsOpen(state.isOpen);
  };

  return (
    <React.Fragment>
      <Breakpoint small down tagName={MobileWrapper}>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Button extend={menuButtonoStyle} onClick={() => setIsOpen(true)}>
            <HamurgerIcon />
          </Button>

          <Menu
            noOverlay
            isOpen={isOpen}
            right
            styles={styles}
            customBurgerIcon={false}
            onStateChange={isMenuOpen}
          >
            <FormattedMessage
              id="header.searchPlaceholder"
              defaultMessage="Search people"
            >
              {message => (
                <PersonAutocomplete
                  placeholder={message}
                  onSelect={onPersonSelect}
                  extend={autocompleteStyle}
                />
              )}
            </FormattedMessage>
            <CornerButton extend={randomButtonStyle} onClick={onRandomClick}>
              {isRandomLoading ? (
                <Loader />
              ) : (
                <FormattedMessage
                  id="header.buttons.random"
                  defaultMessage="Random"
                />
              )}
            </CornerButton>

            <SidebarLinks />
            <UnitSwitch
              extend={{
                maxHeight: '30px',
                margin: '15px 0',
              }}
            />
            <MobileAuthWrapper>
              {isLoggedIn ? (
                <React.Fragment>
                  <AccAvatar />
                  <Flex column>
                    <Name>{user.displayName}</Name>
                    <Flex>
                      <AccLink href={paths.ACCOUNT}>
                        <FormattedMessage
                          id="header.buttons.profile"
                          defaultMessage="Profile"
                        />
                      </AccLink>
                      <AccLink extend={accLinkMargin} onClick={onSignoutClick}>
                        <FormattedMessage
                          id="header.buttons.signout"
                          defaultMessage="Sign out"
                        />
                      </AccLink>
                    </Flex>
                  </Flex>
                </React.Fragment>
              ) : (
                <Flex extend={{ justifyContent: 'center', width: '100%' }}>
                  <CornerButton href="/signin" extend={cornerButtonStyle}>
                    <FormattedMessage
                      id="header.buttons.signin"
                      defaultMessage="Login"
                    />
                  </CornerButton>
                  <CornerButton href="/signup" extend={cornerButtonStyle}>
                    <FormattedMessage
                      id="header.buttons.signup"
                      defaultMessage="Register"
                    />
                  </CornerButton>
                </Flex>
              )}
            </MobileAuthWrapper>
          </Menu>
        </ClickAwayListener>
      </Breakpoint>
      <Breakpoint medium up>
        {!isLoggedIn ? (
          <Wrapper transparent={1}>
            <CornerButton href="/signin" extend={cornerButtonStyle}>
              <FormattedMessage
                id="header.buttons.signin"
                defaultMessage="Login"
              />
            </CornerButton>
            <CornerButton href="/signup" extend={cornerButtonStyle}>
              <FormattedMessage
                id="header.buttons.signup"
                defaultMessage="Register"
              />
            </CornerButton>
          </Wrapper>
        ) : (
          <Wrapper>
            <AccAvatar />
            <Flex column>
              <Name>{user.displayName}</Name>
              <Flex>
                <AccLink href={paths.ACCOUNT}>
                  <FormattedMessage
                    id="header.buttons.profile"
                    defaultMessage="Profile"
                  />
                </AccLink>
                <AccLink extend={accLinkMargin} onClick={onSignoutClick}>
                  <FormattedMessage
                    id="header.buttons.signout"
                    defaultMessage="Sign out"
                  />
                </AccLink>
              </Flex>
            </Flex>
          </Wrapper>
        )}
      </Breakpoint>
    </React.Fragment>
  );
};

export default AuthNav;
