import { combineReducers } from 'redux';
import { assoc } from 'ramda';
import { ACTION_SEARCH_DATE } from '../actions/types';
import { suffixDone } from '../actions/utils';
import { createLoadingReducer } from './utils';

const loading = createLoadingReducer(ACTION_SEARCH_DATE);

const people = (state = {}, action) => {
  switch (action.type) {
    case suffixDone(ACTION_SEARCH_DATE):
      return action.payload.data.reduce(
        (list, row) => assoc(row.id, row, list),
        {},
      );

    default:
      return state;
  }
};

export default combineReducers({ loading, people });
