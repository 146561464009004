import React from 'react';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { createComponent, createComponentWithProxy } from 'react-fela';
import { UNITS_METRIC, UNITS_IMPERIAL } from 'config';

import Flex from 'components/Flex';
import ButtonBase from 'components/Button';
import { changeUnits } from '../common/actions';

const Button = createComponentWithProxy(
  ({ active, theme }) => ({
    backgroundColor: active
      ? theme.borderColor
      : theme.buttons.primary.backgroundColor,
    padding: '0 15px',
    height: '100%',
    color: active ? theme.buttons.primary.backgroundColor : '#fff',
    fontStyle: active ? 'italic' : 'normal',
  }),
  ButtonBase,
);

const Wrapper = createComponent(
  {
    flex: '1 0',
    justifyContent: 'center',
  },
  Flex,
);
const UnitSwitch = props => {
  const usedUnits = useMappedState(({ units }) => units);
  const dispatch = useDispatch();

  const onClickHandler = units => () => dispatch(changeUnits(units));
  return (
    <Wrapper {...props}>
      <Button
        active={usedUnits === UNITS_METRIC}
        onClick={onClickHandler(UNITS_METRIC)}
      >
        km
      </Button>
      <Button
        active={usedUnits === UNITS_IMPERIAL}
        onClick={onClickHandler(UNITS_IMPERIAL)}
      >
        mi
      </Button>
    </Wrapper>
  );
};

export default UnitSwitch;
