import React from 'react';

import { createComponent, createComponentWithProxy } from 'react-fela';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { push } from 'connected-react-router';

import Flex from 'components/Flex';
import Link from 'components/Link';
import Loader from 'components/Loader';
import CornerButton from 'components/CornerButton';
import PersonAutocomplete from 'components/PersonAutocomplete';
import UnitSwitch from 'components/UnitSwitch';
import AuthNav from 'components/AuthNav';
import { randomPerson } from 'common/actions';
import { FormattedMessage } from 'react-intl';

const Wrapper = createComponent(
  {
    backgroundColor: 'rgba(52, 61, 79, 0.9)',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
  },
  Flex,
);

const FakeWrapper = createComponent({
  height: '70px',
  display: 'block',
});

const logoUrl = require('logo-emd-pure.svg');

const Logo = createComponentWithProxy(
  {
    color: '#fff',
    width: '200px',
    margin: '0 25px',
    mobile: {
      margin: '0 20px',
    },
    height: '100%',
    backgroundSize: 'contain',
    display: 'flex',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${logoUrl})`,
  },
  Link,
);

const Inner = createComponent(
  {
    padding: '15px 0',
    alignItems: 'center',
    height: '70px',
    flex: '1 0',
    mobile: {
      display: 'none',
    },
  },
  Flex,
);

const InnerMobile = createComponent(
  {
    mobile: {
      display: 'flex',
    },
    display: 'none',
  },
  Inner,
);

const autocompleteStyle = {
  width: '300px',
  marginRight: '10px',
};
const cornerButtonStyle = { margin: '0 10px', height: '100%' };

const Header = () => {
  const dispatch = useDispatch();
  const { loading: isRandomLoading } = useMappedState(state => state.random);

  const onPersonSelect = person =>
    person && dispatch(push(`/detail/${person.id}`));

  const onRandomClick = () => dispatch(randomPerson());

  return (
    <React.Fragment>
      <FakeWrapper />
      <Wrapper>
        <InnerMobile>
          <Logo href="/" />
        </InnerMobile>
        <Inner>
          <Logo href="/" />

          <FormattedMessage
            id="header.searchPlaceholder"
            defaultMessage="Search people"
          >
            {message => (
              <PersonAutocomplete
                placeholder={message}
                onSelect={onPersonSelect}
                extend={autocompleteStyle}
              />
            )}
          </FormattedMessage>
          {/* <CornerButton extend={cornerButtonStyle}>
            <FormattedMessage
              id="header.buttons.advanced"
              defaultMessage="Advanced search"
            />
          </CornerButton> */}
          <CornerButton extend={cornerButtonStyle} onClick={onRandomClick}>
            {isRandomLoading ? (
              <Loader />
            ) : (
              <FormattedMessage
                id="header.buttons.random"
                defaultMessage="Random"
              />
            )}
          </CornerButton>
          <UnitSwitch
            extend={{
              marginLeft: '10px',
              justifyContent: 'flex-start',
              height: '100%',
            }}
          />
        </Inner>
        <AuthNav />
      </Wrapper>
    </React.Fragment>
  );
};

export default Header;
