import { createComponentWithProxy } from 'react-fela';
import Link from 'components/Link';

export default createComponentWithProxy(
  ({ theme, disabled }) => ({
    ...theme.buttons[disabled ? 'primaryDisabled' : 'primary'],
    padding: '10px 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }),
  Link,
);
