import { createComponent } from 'react-fela';

const textStyle = {
  fontFamily: 'Roboto, sans-serif',
  color: '#fff',
  margin: 0,
};

export const Paragraph = createComponent(textStyle, 'p');

export default Paragraph;
