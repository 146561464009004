import { createComponent } from 'react-fela';

export default createComponent(
  () => ({
    fontFamily: 'Lato, sans-serif',
    color: '#fff',
    fontWeight: 700,
    marginBottom: '20px',
  }),
  'span',
);
