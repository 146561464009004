import { from, of } from 'rxjs';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_SEARCH_DATE } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchBirthdateSearch = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_SEARCH_DATE)),
    debounceTime(500),
    switchMap(action => {
      const { day, month } = action.payload;

      const fetchPerson = id =>
        firebase
          .firestore()
          .collection('people')
          .doc(id)
          .get()
          .then(
            doc =>
              doc.exists && {
                id,
                ...doc.data(),
              },
          );

      const autocompletePerson = firebase
        .functions()
        .httpsCallable('searchBirthdate');

      return from(autocompletePerson({ day, month })).pipe(
        switchMap(({ data }) =>
          from(Promise.all(data.map(d => fetchPerson(d.id)))),
        ),
        map(data => done(ACTION_SEARCH_DATE, { day, month, data })),
        catchError(e =>
          of(error(ACTION_SEARCH_DATE, { day, month, message: e.message })),
        ),
      );
    }),
  );

export default [watchBirthdateSearch];
