import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createComponent, createComponentWithProxy } from 'react-fela';

import Flex from 'components/Flex';
import TextField from 'components/TextField';
import Loader from 'components/Loader';
import ClickawayListener from 'components/ClickAwayListener';
import { autocompletePerson } from 'common/actions';

const Wrapper = createComponent(
  {
    position: 'relative',
  },
  Flex,
);

const List = createComponent(
  {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: 'rgb(42, 49, 66, 0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    zIndex: 10000,
    color: '#fff',
    backdropFilter: 'blur(5px)',
  },
  Flex,
);

const ListItem = createComponentWithProxy(
  {
    padding: '10px',
    borderBottom: '1px solid rgb(42, 49, 66)',
    width: '100%',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'rgb(52, 61, 79)',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Flex,
);

const ListItemName = createComponentWithProxy(
  {
    display: 'flex',
  },
  'span',
);

const ListItemYear = createComponent(
  {
    display: 'flex',
    fontSize: '0.8rem',
    color: '#ddd',
  },
  'span',
);

const loaderStyle = { height: '50px', width: '100px', fill: '#fff' };

const formatYear = (year, skip = false) =>
  skip || Number(year) === 0
    ? year
    : [Math.abs(year), year > 0 ? 'AD' : 'BC'].join(' ');

const PersonAutocomplete = ({ placeholder, onSelect, ...props }) => {
  const [value, setValue] = useState('');
  const [isOpened, setIsOpened] = useState(false);

  const { data, loading } = useMappedState(
    ({ autocomplete }) =>
      (autocomplete && autocomplete[value]) || { loading: false, data: [] },
  );

  const dispatch = useDispatch();

  const onChange = e => {
    e.preventDefault();
    setValue(e.target.value);
    onSelect(null);

    if (e.target.value.length >= 3) {
      setIsOpened(true);
      dispatch(autocompletePerson(e.target.value));
    } else {
      setIsOpened(false);
    }
  };

  const onItemSelect = row => event => {
    event.preventDefault();

    setValue(row.name);
    onSelect(row);
    setIsOpened(false);
  };

  const ListItems =
    data &&
    data.map(row => (
      <ListItem key={row.id} onClick={onItemSelect(row)}>
        <ListItemName>
          {row.name.substr(0, 20).concat(row.name.length > 30 ? '...' : '')}
        </ListItemName>
        {row.born && row.died && (
          <ListItemYear>
            {formatYear(row.born, row.born >= 0 && row.died > 0)} –{' '}
            {formatYear(row.died, row.born >= 0 && row.died > 0)}
          </ListItemYear>
        )}
      </ListItem>
    ));

  return (
    <ClickawayListener onClickAway={() => setIsOpened(false)}>
      <Wrapper {...props} column>
        <TextField
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={() => setTimeout(() => setIsOpened(false), 300)}
          onFocus={() => setIsOpened(true)}
        />
        {isOpened && (
          <List column>
            {loading ? <Loader extend={loaderStyle} /> : ListItems}
          </List>
        )}
      </Wrapper>
    </ClickawayListener>
  );
};

PersonAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default PersonAutocomplete;
