/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local';

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyD3vXzhPJkUlf5HEY-OKlzIw8bzSpXoKMU',
  authDomain: 'emdpfs.firebaseapp.com',
  databaseURL: 'https://emdpfs.firebaseio.com',
  projectId: 'emdpfs',
  storageBucket: 'emdpfs.appspot.com',
  messagingSenderId: '326198367686',
};

export const analyticsTrackingId = 'UA-152715685-1';

export const UNITS_METRIC = 'metric';
export const UNITS_IMPERIAL = 'imperial';
