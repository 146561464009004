import React from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { push } from 'connected-react-router';

const Link = createComponent(
  {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#fff',
  },
  'a',
  ['href', 'onClick'],
);

const LinkComponent = ({ onClick, href, disabled, ...props }) => {
  const dispatch = useDispatch();

  let onClickHandler = disabled ? () => null : onClick;

  if (href && !onClickHandler) {
    onClickHandler = e => {
      e.preventDefault();
      dispatch(push(href));
      return false;
    };
  }

  return <Link {...props} href={href} onClick={onClickHandler} />;
};

LinkComponent.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LinkComponent;
