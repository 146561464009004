import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACTION_COMPARE_ADD } from 'common/actions/types';
import { suffixStart, done, error } from 'common/actions/utils';

const watchCompareAdd = (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(suffixStart(ACTION_COMPARE_ADD)),
    switchMap(action => {
      const { id, name } = action.payload;

      const fetchPromise = firebase
        .firestore()
        .collection('people')
        .doc(id)
        .get()
        .then(doc => doc.exists && doc.data());

      return from(fetchPromise).pipe(
        map(data =>
          done(ACTION_COMPARE_ADD, {
            id,
            data,
          }),
        ),
        catchError(e =>
          of(
            error(ACTION_COMPARE_ADD, {
              name,
              id,
              message: e.message,
            }),
          ),
        ),
      );
    }),
  );

export default [watchCompareAdd];
