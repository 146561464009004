import React from 'react';

import { Helmet } from 'react-helmet';

import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'common/createStore';

import Header from 'components/Header';
import Flex from 'components/Flex';
import Loader from 'components/Loader';
import Content from 'components/Content';
import Sidebar from 'components/Sidebar';
import RightPanel from 'layouts/RightPanel';
// import Modals from 'components/Modals';

const Signin = React.lazy(() => import('./Signin'));
const Signup = React.lazy(() => import('./Signup'));
const Highlights = React.lazy(() => import('./Highlights'));
const Detail = React.lazy(() => import('./Detail'));
const Welcome = React.lazy(() => import('./Welcome'));
const Compare = React.lazy(() => import('./Compare'));
const MyDistance = React.lazy(() => import('./MyDistance'));
const CouldTheyMet = React.lazy(() => import('./CouldTheyMet'));
const Birthdate = React.lazy(() => import('./Birthdate'));
const GroupsRecent = React.lazy(() => import('./GroupsRecent'));
const Group = React.lazy(() => import('./Group'));
const Account = React.lazy(() => import('./Account'));
const Statistics = React.lazy(() => import('./Statistics'));
const Download = React.lazy(() => import('./Download'));
const About = React.lazy(() => import('./About'));
// const UnsignedLayout = React.lazy(() => import('layouts/UnsignedLayout'));

export const paths = {
  HOME: '/',
  WELCOME: '/',
  ACCOUNT: '/account',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  HIGHLIGHTS: '/pick-of-the-day',
  COMPARE: '/compare',
  COULDTHEYMET: '/could-they-have-met',
  MEASUREMYDISTANCE: '/measure-my-distance',
  BORNONDATE: '/born-on-my-birthday',
  RECENTLISTS: '/recent-groups',
  LISTS: '/public-groups',
  STATISTICS: '/statistics',
  DETAIL: '/detail/:id',
  GROUP: '/group/:id',
  DOWNLOAD: '/downloads',
  ABOUT: '/about',
};

function AppRouter() {
  return (
    <div>
      <Helmet
        titleTemplate="%s - EatMyDust.info"
        defaultTitle="Homepage"
        meta={[
          {
            name: 'description',
            content: 'some description',
          },
        ]}
      />
      {/* <Modals /> */}
      <ConnectedRouter history={history}>
        <Flex column>
          <Header />
          <Flex>
            <Sidebar />
            <React.Suspense
              fallback={
                <Content>
                  <Loader />
                </Content>
              }
            >
              <Switch>
                <Route path={paths.HOME} exact component={Welcome} />
                <Route path={paths.WELCOME} exact component={Welcome} />
                <RightPanel path={paths.COMPARE} exact component={Compare} />
                <RightPanel
                  path={paths.COULDTHEYMET}
                  exact
                  component={CouldTheyMet}
                />
                <RightPanel
                  path={paths.MEASUREMYDISTANCE}
                  exact
                  component={MyDistance}
                />
                <RightPanel
                  path={paths.BORNONDATE}
                  exact
                  component={Birthdate}
                />
                <RightPanel
                  path={paths.RECENTLISTS}
                  exact
                  component={GroupsRecent}
                />
                <RightPanel path={paths.LISTS} exact component={GroupsRecent} />

                <RightPanel path={paths.ACCOUNT} exact component={Account} />
                <RightPanel path={paths.SIGNIN} exact component={Signin} />
                <RightPanel path={paths.SIGNUP} exact component={Signup} />
                <RightPanel path={paths.ABOUT} exact component={About} />
                <RightPanel
                  path={paths.HIGHLIGHTS}
                  exact
                  component={Highlights}
                />
                <RightPanel path={paths.DETAIL} exact component={Detail} />
                <Route path={paths.DOWNLOAD} exact component={Download} />
                <RightPanel path={paths.GROUP} exact component={Group} />
                <RightPanel
                  path={paths.STATISTICS}
                  exact
                  component={Statistics}
                />

                <Route component={Welcome} />
              </Switch>
            </React.Suspense>
          </Flex>
        </Flex>
      </ConnectedRouter>
    </div>
  );
}

export default AppRouter;
