import React from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';

class ClickAwayListener extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.props.onClickAway
    ) {
      this.props.onClickAway();
    } else {
      // event.preventDefault();
    }

    return true;
  }

  render() {
    const { children } = this.props;
    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

ClickAwayListener.propTypes = {
  onClickAway: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default createComponent({}, ClickAwayListener, [
  'onClick',
  'onClickAway',
]);
