import { createStore, compose, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import algoliasearch from 'algoliasearch';
import firebase from 'firebase/app';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import combineEpics from './epics';
import combineReducers from './reducers';
import { firebase as firebaseConfig, analyticsTrackingId } from '../config';
import { ACTION_AUTH } from './actions/types';

export const history = createBrowserHistory();

export default () => {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const algoliaClient = algoliasearch(
    'LC0IMW5IVR',
    'e19ec68aed481971e05836358d5ee752',
  );

  firebase.initializeApp(firebaseConfig);
  const catalogIndex = algoliaClient.initIndex('catalog');

  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      firebase,
      catalogIndex,
      ReactGA,
    },
  });

  ReactGA.initialize(analyticsTrackingId, { debug: true });

  const enhancer = composeEnhancers(
    applyMiddleware(epicMiddleware, routerMiddleware(history)),
  );

  const reducer = combineReducers(history);

  const store = createStore(reducer, enhancer);

  const epics = combineEpics();
  epicMiddleware.run(epics);

  firebase
    .auth()
    .onAuthStateChanged(user =>
      store.dispatch({ type: ACTION_AUTH, payload: user && user.toJSON() }),
    );

  return store;
};
