import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import RightPanel from 'components/RightPanel';
import Content from 'components/Content';

const RightPanelLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <React.Fragment>
        <Content>
          <Component {...matchProps} />
        </Content>
        <RightPanel />
      </React.Fragment>
    )}
  />
);

RightPanelLayout.propTypes = {
  component: PropTypes.any,
};

export default RightPanelLayout;
