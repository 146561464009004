import React from 'react';
import PropTypes from 'prop-types';
import { createComponentWithProxy } from 'react-fela';
import Flex from 'components/Flex';

const Input = createComponentWithProxy(
  () => ({
    background: '#181b28',
    border: '1px solid #181b28',
    borderBottom: '1px solid #18e877',
    color: '#fff',
    padding: '10px 15px',
  }),
  'input',
);

const TextField = ({ input, extend, meta = {}, label, ...props }) => (
  <Flex column extend={extend}>
    {label && <label>{label}</label>}
    <Input type="text" {...input} {...props} />
    {meta.touched && meta.error && <span>{meta.error}</span>}
  </Flex>
);

TextField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.element,
  extend: PropTypes.object,
};

export default TextField;
