import React from 'react';
import PropTypes from 'prop-types';
import { createComponentWithProxy } from 'react-fela';

const Form = createComponentWithProxy(
  {
    display: 'inline-block',
    lineHeight: '14px',
  },
  'form',
);

const Input = createComponentWithProxy(
  {
    padding: 0,
    border: 0,
    color: '#fff',
    background: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  'input',
);

const PaypalButton = ({ useImage = true }) => (
  <Form
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
    target="_top"
  >
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="ZYZSVSJ72QHTQ" />
    {useImage ? (
      <input
        type="image"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
        border="0"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
    ) : (
      <Input type="submit" value="click here" />
    )}
  </Form>
);

PaypalButton.propTypes = {
  useImage: PropTypes.bool,
};

export default PaypalButton;
